import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import menuIcon from '../assets/menu.svg';
import headerLogo from '../assets/everybingo-logo.png';
import headerLogoDark from '../assets/everybingo-logo-dark.png';
import circleIcon from '../assets/circle.svg';
import { useAuth } from '../components/authContext';
import { doc, onSnapshot } from 'firebase/firestore';
import db from '../lib/firebase';

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { user } = useAuth();
  const [favoriteColor, setFavoriteColor] = useState('#000000'); // default color
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);

    const handleChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFavoriteColor(data.favoriteColor || '#000000');
        }
      }, (error) => {
        console.error('Error listening to user document:', error);
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  return (
    <div className="header">
      <div className="headerLeft">
        <img src={menuIcon} alt="Menu" width="15px" />
      </div>
      <div className="headerMiddle">
        <Link to="/">
          <img
            className="headerLogo"
            src={isDarkMode ? headerLogoDark : headerLogo}
            alt="Logo"
          />
        </Link>
      </div>
      <div className="headerRight">
          {user ? (
            // If logged in, show a colored circle that links to /me
            <Link to="/me">
              <div
                   className="favoriteColorCircle" style={{ backgroundColor: favoriteColor }}
              />
            </Link>
          ) : (
            // If not logged in, show a login button similar to play button that links to /login
            <Link to="/login" className="loginButton">
              <div className="playButtonContainer"><h3>login</h3></div>
            </Link>
          )}
        {/* Add the "Play" button */}
        <Link to="/search" className="playButton">
          <div className="playButtonContainer"><h3>play</h3></div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
