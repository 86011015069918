import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import Flickity from 'react-flickity-component';
import db from '../lib/firebase';
import 'flickity/css/flickity.css';

const flickityOptions = {
  initialIndex: 0,
  draggable: true,
  freeScroll: true,
  contain: true,
  wrapAround: false,
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
};

const AllBoards = ({ handleDragStart, handleDragEnd }) => {
  const [boards, setBoards] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const recentDrag = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBoards = async () => {
      const boardsRef = collection(db, 'bingoboards');
      const boardsSnapshot = await getDocs(boardsRef);
      const boardsData = boardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBoards(boardsData);
    };

    fetchBoards();
  }, []);

  const localHandleDragStart = () => {
    setIsDragging(true);
    recentDrag.current = false;
    if (handleDragStart) handleDragStart();
  };

  const localHandleDragEnd = () => {
    setIsDragging(false);
    recentDrag.current = true;
    setTimeout(() => {
      recentDrag.current = false;
    }, 300);
    if (handleDragEnd) handleDragEnd();
  };

  return (
    boards.length > 0 && (
      <Flickity
        className={'boardSectionContainer allBoardsContainer'}
        style={{ height: 'auto' }}
        options={flickityOptions}
        flickityRef={(flkty) => {
          flkty.on('dragStart', localHandleDragStart);
          flkty.on('dragEnd', localHandleDragEnd);
        }}
      >
        {boards.map((board, index) => (
          <div
            className="boardSection allBoards"
            key={board.id + index}
            style={{ backgroundImage: `url(${board.imageUrl})` }}
            onClick={(e) => {
              if (recentDrag.current) return; // Prevent navigation if a drag just occurred
              console.log('Tile clicked: navigating to board', board.slug);
              navigate(`/board/${board.slug}-bingo`);
            }}
          >
            <h3 className="boardTitle">{board.title}</h3>
          </div>
        ))}
      </Flickity>
    )
  );
};

export default AllBoards;
