import React, { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import db from '../lib/firebase';
import '../styles/createBoard.css';
import circleIcon from '../assets/circle.svg';

// Custom Search API credentials
const API_KEY = 'AIzaSyCHsJCRgJm_u3dqdqqNFAOWxOhyScQZ1gg';
const SEARCH_ENGINE_ID = 'b07bd46bf6a6d4e3a';

// Function to query the Google Custom Search API
async function fetchPromoImage(boardTitle) {
  const query = `${boardTitle} imagesize:1280x720`;
  const url = `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&q=${encodeURIComponent(query)}&searchType=image&num=1`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.items && data.items.length > 0) {
      return data.items[0].link; // Return the first image result URL
    } else {
      console.log('No image found for query:', query);
      return null;
    }
  } catch (error) {
    console.error('Error fetching image from Google Custom Search:', error);
    return null;
  }
}

const CreateBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get('q') || 'New Board'; // Default to "New Board" if no query parameter

  const [formData, setFormData] = useState({
    title: title, // Initialize with title or default
    isPublic: null,
    category: '',
    featuredDesc: '',
    predictionHelp: 'yes please',
  });

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingFeaturedDesc, setIsEditingFeaturedDesc] = useState(false);
  const [step, setStep] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestion, setCurrentSuggestion] = useState('');

  // Handle input change for each field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle selection for isPublic
  const handlePublicChoice = (isPublic) => {
    setFormData({ ...formData, isPublic });
    setStep(step + 1);
  };

  // Handle navigation between steps
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // Submit form data to Firestore
  const handleSubmit = async () => {
    const { title, isPublic, category, featuredDesc } = formData;

    // Generate a slug and fetch a promotional image
    const slug = createSlug(title);
    const imageUrl = await fetchPromoImage(title);

    try {
      // Create a new board document
      const boardRef = await addDoc(collection(db, 'bingoboards'), {
        title,
        slug,
        isPublic,
        category,
        featuredDesc,
        imageUrl: imageUrl || 'No image available',
        eventDate: Timestamp.fromDate(new Date()),
        status: 'final',
        suggestionCutoff: Timestamp.fromDate(new Date()),
        votingCutoff: Timestamp.fromDate(new Date()),
        finalizedTiles: [],
      });

        // Add each suggestion as a document in the 'suggestions' subcollection
        if (suggestions.length > 0) {
          await Promise.all(
            suggestions.map(suggestion =>
              addDoc(collection(db, 'bingoboards', boardRef.id, 'suggestions'), {
                content: suggestion,
                likes: 0,
                timestamp: Timestamp.fromDate(new Date())
              })
            )
          );
        }

      console.log(`Board created with ID: ${boardRef.id}`);
      navigate(`/board/${slug}`); // Redirect to the newly created board
    } catch (error) {
      console.error('Error creating board:', error);
    }
  };

  // Helper function to generate a slug
  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-|-$/g, '')
      .trim();
  };

  // Page Components
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h2>
              Let's make a new bingo board called {isEditingTitle ? (
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  onBlur={() => {
                    if (!formData.title.trim()) {
                      setFormData({ ...formData, title: 'New Board' });
                    }
                    setIsEditingTitle(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (!formData.title.trim()) {
                        setFormData({ ...formData, title: 'New Board' });
                      }
                      setIsEditingTitle(false);
                    }
                  }}
                  autoFocus
                  className="boardNameInput"
                />
              ) : (
                <span
                  className="boardNameBox"
                  onClick={() => setIsEditingTitle(true)}
                  style={{ textDecoration: 'underline' }}
                >
                  {formData.title}
                  <img src={circleIcon} alt="edit icon" className="boardNameIcon" />
                </span>
              )} <br /> who can play?
            </h2>
            <div className="choiceButtonsRow">
              <div className="choiceButton" onClick={() => handlePublicChoice(true)}>
                <div className="choiceCardTop">
                  <h3>everyone</h3>
                  <p>everyone can see this board and share predictions</p>
                </div>
                <div className="choiceCardBottom">
                  <h4>recommended for big events</h4>
                  <p>album drops, awards shows, sporting events, etc.</p>
                </div>
              </div>
              <div className="choiceButton" onClick={() => handlePublicChoice(false)}>
                <div className="choiceCardTop">
                  <h3>invite-only</h3>
                  <p>only people with the link can see the board and share predictions</p>
                </div>
                <div className="choiceCardBottom">
                  <h4>recommended for small events</h4>
                  <p>private screenings, bachelorette parties, etc.</p>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        const handleAddSuggestion = (e) => {
          if (e.key === 'Enter' && currentSuggestion.trim()) {
            setSuggestions([...suggestions, currentSuggestion.trim()]);
            setCurrentSuggestion('');
            e.preventDefault();
          }
        };
 
        const handleRemoveSuggestion = (index) => {
          const updatedSuggestions = suggestions.filter((_, i) => i !== index);
          setSuggestions(updatedSuggestions);
        };
 
        return (
          <>
            <h2>add some predictions to your board</h2>
            <p className="subtitle">hit enter between each one. for best results, only a few words each! <br /> you can always add more later.</p>
            <section className="inputSection">
              <input
                type="text"
                name="suggestionInput"
                value={currentSuggestion}
                onChange={(e) => setCurrentSuggestion(e.target.value)}
                onKeyDown={handleAddSuggestion}
                placeholder="Type a suggestion and press Enter..."
                className="suggestionInput"
              />
            </section>
            <section className="suggestionsList">
              {suggestions.map((suggestion, index) => (
                <div key={index} className="suggestionBox">
                  <span>{suggestion}</span>
                  <button className="removeButton" onClick={() => handleRemoveSuggestion(index)}>X</button>
                </div>
              ))}
            </section>
            <div className="centeredNavigationButtons">
              <button className="navButton" onClick={prevStep}>Back</button>
              <button className="navButton" onClick={nextStep}>Next</button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h2>confirm these details before we play</h2>
            {/* Row One: Bingo Board Name (editable) */}
            <div className="reviewRow" style={{ width: '100%', marginBottom: '1rem' }}>
              <label>bingo board name</label>
              {isEditingTitle ? (
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  onBlur={() => {
                    if (!formData.title.trim()) {
                      setFormData({ ...formData, title: 'New Board' });
                    }
                    setIsEditingTitle(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (!formData.title.trim()) {
                        setFormData({ ...formData, title: 'New Board' });
                      }
                      setIsEditingTitle(false);
                    }
                  }}
                  autoFocus
                  className="suggestionInput"
                />
              ) : (
                <h3
                  onClick={() => setIsEditingTitle(true)}
                  className="suggestionInput"
                  style={{ cursor: 'pointer', margin: 0, padding: '1rem', textAlign: 'center' }}
                >
                  {formData.title}
                </h3>
              )}
            </div>
            
            {/* Row Two: Event Category */}
            <div className="reviewRow" style={{ width: '100%', marginBottom: '1rem' }}>
              <label>what kind of event is it?</label>
              <select
                name="category"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className="suggestionInput"
                style={{ width: '100%' }}
              >
                <option value="">Select</option>
                <option value="movie">movie</option>
                <option value="tv show">tv show</option>
                <option value="video game">video game</option>
                <option value="event">event</option>
                <option value="something else">something else</option>
              </select>
            </div>
            
            {/* Row Three: Who Can Play? */}
            <div className="reviewRow" style={{ width: '100%', marginBottom: '1rem' }}>
              <label>who can play?</label>
              <select
                name="isPublic"
                value={formData.isPublic === true ? 'everyone' : 'invite-only'}
                onChange={(e) => setFormData({ ...formData, isPublic: e.target.value === 'everyone' })}
                className="suggestionInput"
                style={{ width: '100%' }}
              >
                <option value="everyone">everyone</option>
                <option value="invite-only">invite-only</option>
              </select>
            </div>
            
            {/* Row Four: Prediction Help */}
            <div className="reviewRow" style={{ width: '100%', marginBottom: '1rem' }}>
              <label>prediction help</label>
              <select
                name="predictionHelp"
                value={formData.predictionHelp}
                onChange={(e) => setFormData({ ...formData, predictionHelp: e.target.value })}
                className="suggestionInput"
                style={{ width: '100%' }}
              >
                <option value="yes please">yes please</option>
                <option value="no way">no way</option>
              </select>
            </div>
            
            {/* Row Five: Event Description */}
            <div className="reviewRow" style={{ width: '100%', marginBottom: '1rem' }}>
              <label>describe the event in a few words</label>
              {isEditingFeaturedDesc ? (
                <input
                  type="text"
                  value={formData.featuredDesc}
                  onChange={(e) => setFormData({ ...formData, featuredDesc: e.target.value })}
                  onBlur={() => setIsEditingFeaturedDesc(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsEditingFeaturedDesc(false);
                    }
                  }}
                  autoFocus
                  className="suggestionInput"
                />
              ) : (
                <p
                  onClick={() => setIsEditingFeaturedDesc(true)}
                  className="suggestionInput"
                  style={{ cursor: 'pointer', margin: 0, padding: '1rem', textAlign: 'center' }}
                >
                  {formData.featuredDesc || 'Click to add description'}
                </p>
              )}
            </div>
            
            {/* Navigation Buttons */}
            <div className="centeredNavigationButtons">
              <button className="navButton" onClick={prevStep}>Back</button>
              <button className="navButton" onClick={handleSubmit}>Confirm and Create Board</button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="createBoardContainer">
      {renderStep()}
    </div>
  );
};

export default CreateBoard;
