import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import db from '../lib/firebase';
import '../styles/search.css';
import useDebounce from '../lib/useDebounce'; // Import debounce hook

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBoards, setFilteredBoards] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300); // Debounce input by 300ms
  const navigate = useNavigate();

  // Query Firestore when the debounced search term changes
  useEffect(() => {
    const searchBoards = async () => {
      if (debouncedSearchTerm.trim() === '') {
        setFilteredBoards([]); // Clear results if search term is empty
        return;
      }

      try {
        // Create a query to search Firestore for matching board titles
        const boardsRef = collection(db, 'bingoboards');
        const q = query(
          boardsRef,
          where('title', '>=', debouncedSearchTerm),
          where('title', '<=', debouncedSearchTerm + '\uf8ff') // Firestore string range query
        );

        const querySnapshot = await getDocs(q);
        const boardsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setFilteredBoards(boardsData); // Update state with search results
      } catch (error) {
        console.error('Error querying Firestore:', error);
      }
    };

    searchBoards();
  }, [debouncedSearchTerm]);

  // Navigate to the create board page with the encoded search term
  const handleCreateBoard = () => {
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    console.log(`Navigating to: /create?q=${encodedSearchTerm}`);
    navigate(`/create?q=${encodedSearchTerm}`);
  };

  return (
    <div className="searchContainer">
      <div className="searchTitle"><h2>Find a bingo game, or make your own</h2></div>
      <input
        type="text"
        className="searchInput"
        placeholder="Start typing..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Only display the results if the searchTerm is not empty */}
      {searchTerm.trim() !== '' && (
        <div className="resultsGrid">
          {filteredBoards.length === 0 ? (
            // If no results, show only the "create new board" tile
            <div
              className="boardSection createBoardTile"
              onClick={handleCreateBoard} // Use click handler to navigate
            >
              <h2 className="boardTitle">
                Make a new board called "{searchTerm}"
              </h2>
            </div>
          ) : (
            <>
              {filteredBoards.map((board) => (
                <div
                  className="boardSection"
                  key={board.id}
                  style={{ backgroundImage: `url(${board.imageUrl})` }}
                >
                  <h2 className="boardTitle">{board.title}</h2>
                </div>
              ))}
              {/* Display "create new board" tile based on results count */}
              <div
                className="boardSection createBoardTile"
                onClick={handleCreateBoard} // Use click handler to navigate
              >
                <h2 className="boardTitle">Make a new board called "{searchTerm}"</h2>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
